/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../modules/auth/models/AccountUserWrapperModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import * as dashboard from '../dashboard/redux/DashboardRedux'
import AvatarRenderer from '../../modules/profile/components/AvatarRenderer'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {bundleKeys} from '../../helpers/provider/BountiesProvider'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {bundleLogo} from '../../helpers/provider/ProviderThemeData'
import BlockUI from '../../modules/profile/components/BlockUi'
import {Roles} from '../../modules/auth/models/RolesEnum'
import {RoleCheckMin} from '../../helpers/RoleRestriction'

function DashboardPage() {
  const dashboardData = useSelector<RootState>(({dashboard}) => dashboard, shallowEqual) as any

  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const intl = useIntl()
  const dispatch = useDispatch()
  const newDateObj = moment().subtract(30, 'minutes').valueOf()
  const bounties = Object.keys(bundleKeys)

  useEffect(() => {
    if (dashboardData.bounties === null) {
      bounties.map((bounty, index) =>
        dispatch(
          dashboard.dashboardActions.dashboardCountBy(
            'bounties',
            bounty,
            'string',
            '==',
            'bounties'
          )
        )
      )
    }

    if (dashboardData.lastModifiedUsers === null) {
      dispatch(
        dashboard.dashboardActions.dashboardCountBy(
          'metaData.lastModifiedTimestamp',
          newDateObj,
          'number',
          '>',
          'lastModifiedUsers'
        )
      )
    }

    if (dashboardData.userBanned === null) {
      dispatch(
        dashboard.dashboardActions.dashboardCountBy(
          'status',
          'BANNED,SUSPENDED',
          'string',
          '==',
          'user'
        )
      )
    }

    dispatch(
      dashboard.dashboardActions.topUsers(
        'metaData.counters.nbAchievements',
        '0',
        'metaData.counters.nbAchievements',
        'DESC',
        'number',
        '==',
        'achievements'
      )
    )

    dispatch(
      dashboard.dashboardActions.topUsers(
        'metaData.counters.nbFriendsApproved',
        '0',
        'metaData.counters.nbFriendsApproved',
        'DESC',
        'number',
        '==',
        'friends'
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let screenWidth = window.screen.width

  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0 flex-sm-nowrap mb-3'>
              <div className='d-flex flex-wrap'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <AvatarRenderer
                      avatarLayers={admin.user.avatarLayers}
                      dimension={150}
                      uid={admin.user.uid}
                    />
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <span className='text-gray-800 fs-2 fw-bolder me-1'>
                      {intl.formatMessage({id: 'HOMEPAGE.HELLO'})} {admin.user.nickname}
                      <span className='fs-2 text-gray-700 fw-bolder'>{admin.user.nicknameId}</span>
                    </span>
                    <Link to='/logout' className='btn btn-sm btn-light-danger ms-6'>
                      {intl.formatMessage({id: 'LOGOUT'})}
                    </Link>
                  </div>
                  <div className='mb-1'>
                    <span className='badge badge-light-success fw-bolder fs-7 px-2 py-1'>
                      {admin.account.role}
                    </span>
                  </div>
                  <div className='mb-1'>
                    <span className='fw-bold text-muted fs-7 me-6'>{admin.account.email}</span>
                  </div>
                  <div className='mb-5'>
                    <a
                      href={'/users/' + admin.user.wtUserUid}
                      className='fw-bold text-muted fs-7 me-6 text-hover-primary '
                    >
                      {admin.user.wtUserUid}
                    </a>
                  </div>
                  {/* <div className='card-title align-items-start flex-column'>
                    <p className='text-gray-800 fs-3 fw-bolder me-1'>
                      {intl.formatMessage({id: 'HOMEPAGE.EXTERNALLINKS'})} :
                    </p>
                  </div>
                  <div className='d-flex flex-wrap mb-5'>
                    <a
                      href='https://trello.com/walkietalkiecommunication'
                      rel='noreferrer'
                      target='_blank'
                      className='btn btn-info me-4 mt-4'
                    >
                      <i className='fab fa-trello fs-4 me-2'></i>Trello
                    </a>
                    <a
                      href='https://drive.google.com/drive/u/0/folders/1rOS9kiBcELU9tc_6cd_0oV9x8r294GWb'
                      rel='noreferrer'
                      target='_blank'
                      className='btn btn-info me-4 mt-4'
                    >
                      <i className='fab fa-google-drive fs-4 me-2'></i>Drive
                    </a>
                    <a
                      href='https://console.firebase.google.com/u/0/project/walkie-talkie-communication/overview'
                      rel='noreferrer'
                      target='_blank'
                      className='btn btn-info me-4 mt-4'
                    >
                      <i className='fa fa-link fs-4 me-2'></i>Firebase
                    </a>
                    <a
                      href='https://analytics.amplitude.com/picslocorp/workspace'
                      rel='noreferrer'
                      target='_blank'
                      className='btn btn-info me-4 mt-4'
                    >
                      <i className='fa fa-link fs-4 me-2'></i>Amplitude
                    </a>
                    <a
                      href='https://console.cloud.google.com/home/dashboard?authuser=0&hl=fr&project=walkie-talkie-communication'
                      rel='noreferrer'
                      target='_blank'
                      className='btn btn-info me-4 mt-4'
                    >
                      <i className='fa fa-link fs-4 me-2'></i>GCP
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-md-6'>
          <div className='card card-custom bg-success card-stretch gutter-b mt-5'>
            <div className='card-body'>
              <i className='fa fa-users fs-3 text-white'></i>

              <span className='card-title font-weight-bolder fs-3 text-white mb-0 mt-6 d-block'>
                {dashboardData?.lastModifiedUsers ? dashboardData.lastModifiedUsers : '...'}
              </span>
              <span className='font-weight-bold text-white'>
                {intl.formatMessage({id: 'DASHBOARD.USERSLAST'})}
              </span>
            </div>
          </div>
          <div className='card card-custom bg-warning card-stretch gutter-b mt-5'>
            <div className='card-body'>
              <i className='fa fa-ban fs-3 text-white'></i>
              <span className='card-title font-weight-bolder fs-3 text-white  mb-0 mt-6 d-block'>
                {dashboardData?.userSuspended ? dashboardData?.userSuspended : '...'}
              </span>
              <span className='font-weight-bold text-white font-size-sm'>
                {intl.formatMessage({id: 'DASHBOARD.SUSPENDED'})}
              </span>
            </div>
          </div>
          <div className='card card-custom bg-danger card-stretch gutter-b mt-5'>
            <div className='card-body'>
              <i className='fa fa-skull fs-3 text-white'></i>
              <span className='card-title font-weight-bolder fs-3 text-white mb-0 mt-6 d-block'>
                {dashboardData?.userBanned ? dashboardData?.userBanned : '...'}
              </span>
              <span className='font-weight-bold text-white font-size-sm'>
                {intl.formatMessage({id: 'DASHBOARD.BANNED'})}
              </span>
            </div>
          </div>
        </div>
        <div className='col-xl-9 col-md-6'>
          <div className='card table-responsive mt-5' style={{backgroundColor: '#ededeb'}}>
            <div className='card-body'>
              <div className='mb-5 ms-2'>
                <span className='text-gray-800 fs-2 fw-bolder'>Bounties</span>
              </div>
              {dashboardData.bounties ? (
                Object.entries(dashboardData.bounties)
                  .sort(([, a]: any, [, b]: any) => b - a)
                  .map((entry: any, index) => {
                    const [key, value] = entry
                    return (
                      <div key={index} className='d-flex align-items-center mb-5'>
                        <div className='flex-grow-1'>
                          <img
                            alt=''
                            className='h-20px logo flex-shrink-0 me-5 mb-1'
                            src={toAbsoluteUrl(bundleLogo[key])}
                          />
                          <span className='text-dark fw-bolder fs-4'>{key}</span>
                        </div>
                        <span
                          className={
                            'ms-10 badge badge-' +
                            (index === 0 ? 'danger' : index === 1 ? 'warning' : 'success')
                          }
                        >
                          {value}
                        </span>
                      </div>
                    )
                  })
              ) : (
                <BlockUI loading={true} div={true} />
              )}
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card mt-10 table-responsive' style={{backgroundColor: '#ededeb'}}>
            <div className='card-body'>
              <div className='mb-5 ms-2'>
                <span className='text-gray-800 fs-2 fw-bolder'>
                  {intl.formatMessage({id: 'DASHBOARD.TOPFRIENDS'})}
                </span>
              </div>
              {dashboardData.topUsersFriendsList ? (
                dashboardData.topUsersFriendsList.map((data: UserWrapperModel, index: any) => {
                  return (
                    <div key={index} className='d-flex align-items-center mb-7 '>
                      <div className='symbol symbol-50px me-5'>
                        <div className='d-flex align-items-center'>
                          <AvatarRenderer
                            avatarLayers={data.user.profile.avatarLayers}
                            dimension={50}
                            uid={data.user.uid}
                          />
                        </div>
                      </div>
                      <div className='flex-grow-1'>
                        <Link
                          to={{pathname: '/users/' + data.user.uid, state: {userData: data.user}}}
                        >
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {data.user.nickname}
                          </span>
                        </Link>
                        <span className='text-muted d-block fw-bold '>{data.user.uid}</span>
                      </div>
                      <span
                        className={
                          'ms-5 badge badge-' +
                          (index === 0 ? 'danger' : index === 1 ? 'warning' : 'success')
                        }
                      >
                        {data.user.metaData.counters?.nbFriendsApproved}
                      </span>
                    </div>
                  )
                })
              ) : (
                <BlockUI loading={true} div={true} />
              )}
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card mt-10 table-responsive' style={{backgroundColor: '#ededeb'}}>
            <div className='card-body'>
              <div className='mb-5 ms-2'>
                <span className='text-gray-800 fs-2 fw-bolder'>
                  {' '}
                  {intl.formatMessage({id: 'DASHBOARD.TOPACHIEVEMENTS'})}
                </span>
              </div>
              {dashboardData.topUsersAchievementsList ? (
                dashboardData.topUsersAchievementsList.map(
                  (data: UserWrapperModel, index: number) => {
                    return (
                      <div key={index} className='d-flex align-items-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <div className='d-flex align-items-center'>
                            <AvatarRenderer
                              avatarLayers={data.user.profile.avatarLayers}
                              dimension={50}
                              uid={data.user.uid}
                            />
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <Link
                            to={{pathname: '/users/' + data.user.uid, state: {userData: data.user}}}
                          >
                            <span className='text-dark fw-bolder text-hover-primary fs-6 '>
                              {data.user.nickname}
                            </span>
                          </Link>
                          <span className='text-muted d-block fw-bold'>{data.user.uid}</span>
                        </div>
                        <span
                          className={
                            'badge badge-' +
                            (index === 0 ? 'danger' : index === 1 ? 'warning' : 'success')
                          }
                        >
                          {data.user.metaData.counters?.nbAchievements}
                        </span>
                      </div>
                    )
                  }
                )
              ) : (
                <BlockUI loading={true} div={true} />
              )}
            </div>
          </div>
        </div>
        {RoleCheckMin(Roles.Admin, admin.account.role) && screenWidth > 600 && (
          <div className='d-flex justify-content-center mt-20 mb-20 table-responsive'>
            <iframe
              className='frame'
              title='analytics'
              src='https://analytics.amplitude.com/share/embed/366acda8-78f3-42d8-a512-ebe6ec3c7122'
              frameBorder='0'
              width='90%'
              height='800'
            ></iframe>
          </div>
        )}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
